
module.exports = {
  firebase: {
    apiKey: "AIzaSyDE7jweguM4BlF3z58LemuApYTiYC3nsLY",
    authDomain: "stanislaus-bc0a1.firebaseapp.com",
    databaseURL: "https://stanislaus-bc0a1-default-rtdb.firebaseio.com",
    projectId: "stanislaus-bc0a1",
    storageBucket: "stanislaus-bc0a1.appspot.com",
    messagingSenderId: "358507469667",
    appId: "1:358507469667:web:b0e030377b447a54031156",
    measurementId: "G-XPYFZKYVE1"
  }
}

