import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from '../views/dashboard.vue'
import Login from '@/views/login.vue'
import notFound from '@/views/notFound.vue'
import kioskDemo from '@/views/kioskDemoView.vue'
import { auth } from "@/firebase";
import store from '../store'
Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'Not Found',
    component: notFound
  },  
  {
    path: '/',
    // name: 'Dashboard',
    component: dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home.vue'),
      },
      {
        path: '/userManagement',
        name: 'userManagement',
        component: () => import('@/views/admin/userManagement.vue'),
        beforeEnter: (to, from, next) => {
          if(store.getters.isSuperAdmin || store.getters.isAdmin || store.getters.isClientAdmin){
            next()
          }else{
            next('/');
          }
        }
      },
      {
        path: '/groupManagement',
        name: 'groupManagement',
        component: () => import('@/views/admin/groupManagement.vue'),
        beforeEnter: (to, from, next) => {
          if(store.getters.isSuperAdmin || store.getters.isAdmin || store.getters.isClientAdmin){
            next()
          }else{
            next('/');
          }
        }
      },
      {
        path: '/roleManagement',
        name: 'roleManagement',
        component: () => import('@/views/admin/roleManagement.vue'),
        beforeEnter: (to, from, next) => {
          if(store.getters.isSuperAdmin || store.getters.isAdmin || store.getters.isClientAdmin){
            next()
          }else{
            next('/');
          }
        }
      },
      {
        path: '/kioskManagement',
        name: 'kioskManagement',
        component: () => import('@/views/admin/kioskManagement.vue'),
        beforeEnter: (to, from, next) => {
          if(store.getters.isSuperAdmin || store.getters.isAdmin || store.getters.isClientAdmin){
            next()
          }else{
            next('/');
          }
        }
      },
      {
        path: '/kioskAnalytics',
        name: 'kioskAnalytics',
        component: () => import('@/views/admin/kioskAnalytics.vue')
      },
      {
        path: '/userAnalytics',
        name: 'userAnalytics',
        component: () => import('@/views/admin/userAnalytics.vue'),
      },
      {
        path: '/configureClient',
        name: 'configureClient',
        component: () => import('@/views/configureClient.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters.isSuperAdmin) {
            next()
          } else {
            next('/');
          }
        }
      },
      {
        path: '/configureKiosk',
        name: 'configureKiosk',
        component: () => import('@/views/configureKiosk.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters.isSuperAdmin) {
            next()
          } else {
            next('/');
          }
        }
      },
      {
        path: '/kioskUpdates',
        name: 'kioskUpdates',
        component: () => import('@/views/general/kioskUpdates.vue'),
      },
      {
        path: '/telepresence',
        name: 'telepresence',
        component: () => import('@/views/general/telepresence.vue'),
      },
      {
        path: '/myProfile',
        name: 'profile',
        component: () => import('@/views/profile.vue'),
        meta: {
          title: 'My Profile'
        }
      }

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true,
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/Admin.vue'),
    meta: {
      requiresGuest: true,
    }
  },
  {
    path: '/registration/:id',
    name: 'Registration',
    component: () => import('../views/invitedUserRegistration'),
    meta: {
      requiresGuest: true,
    }
  },
  {
    path: '/kioskRegistration/:id',
    name: 'kioskRegistration',
    component: () => import('../views/invitedKioskRegistration.vue'),
    meta: {
      requiresGuest: true,
    }
  },
  {
    path: '/kioskDemo',               // Kiosk Demo fixed resolution 1920x1080
    name: 'Kiso App Demo',
    component: kioskDemo,
    meta: {      
      requiresAuth: true,
    }
  },  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    store.dispatch("checkInitialization").then(response => {
      if (response) {
        next('/admin');
      } else {
        next('/login');
      }
    })
  }
  else {
    next();
  }
})

export default router
