import * as fb from "../../firebase";
import moment from "moment";
import axios from "axios";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    pullData({ commit }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://cms.mdcourts.arsconnect.com/user-sessions?kioskId=tN0cZxnv0rNYcNm80DaF42o7KUx2",
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios.request(config).then((response) => {
        let sessionArray = response.data;
        sessionArray.forEach((session) => {
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://cms.elpaso.arsconnect.com/user-sessions",
            headers: {
              "Content-Type": "application/json",
            },
            data: session,
          };
          axios
            .request(config)
            .then((response) => {
              //console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
              console.log(error);
            });
          console.log("Response: ", session);
        });
      });
    },
    pullSessionData({ commit }) {
        console.log('Coming in here')
      const startDate = new Date("2024-08-01");
      const endDate = new Date("2024-09-31");
      fb.userSessionCollection //userSessionCollection
        .where("kioskID", "==", "sZ2ch5Y3rofVnxXB3PTEwPLSEoz2")
        .where("userSessionStartTime", ">=", startDate)
        .where("userSessionStartTime", "<", endDate)
        .get()
        .then((session) => {
            console.log('At session pull: ', session)
          session.docs.forEach((doc) => {
            let data = {
              kioskId: doc.data().kioskID,
              userSession: doc.data().userSessionLogs,
              sessionStartTime: doc.data().userSessionStartTime.toDate(),
              sessionEndTime: doc.data().userSessionEndTime.toDate(),
            };
            console.log("Session: ", data);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: "https://cms.stanislaus.arsconnect.com/user-sessions",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            axios
              .request(config)
              .then((response) => {
                //console.log(JSON.stringify(response.data));
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
    },

    pullQnaData({ commit }) {
      fb.qnaCollection
        .where("kioskID", "==", "exLeNOqTnlhqn3CJlBD5k5q24A63")
        .get()
        .then((session) => {
          session.docs.forEach((doc) => {
            let data = {
              kioskID: doc.data().kioskID,
              result: doc.data().result,
              timeStamp: doc.data().timeStamp.toDate(),
              transcript: doc.data().transcript,
            };
            console.log("Session: ", data);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: "https://cms.ottawa.arsconnect.com/qnas",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            axios
              .request(config)
              .then((response) => {
                //console.log(JSON.stringify(response.data));
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
    },
  },
};
